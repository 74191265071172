import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import Events from '@activebrands/core-web/libs/Events';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('ul', {
    marginTop: '16px',
    paddingBottom: '16px',
});

const Item = styled('li', {
    marginBottom: '16px',
    ...textStyles['Primary/16_94_-1_700'],

    ':last-of-type': {
        marginBottom: '0px',
    },
});

const CategoryListing = ({ items = [], searchMeta, ...rest }) => {
    const [css] = useStyletron();

    const handleClick = (objectID, position) => {
        if (searchMeta) {
            Events.trigger(SearchEvents.CLICK, {
                ...searchMeta,
                objectIDs: [objectID],
                positions: [position + 1],
                eventName: `${TrackingLists.HEADER_SEARCH}: Search`,
                queryID: searchMeta.queryID,
                index: searchMeta.index,
            });
        }
    };

    return items.length > 0 ? (
        <Wrapper {...rest}>
            {items.map(({ id, label, to, objectID, ...item }, index) => (
                <Item key={id}>
                    <Link
                        className={css({ display: 'flex' })}
                        to={to}
                        {...item}
                        onClick={() => handleClick(objectID, index + 1)}
                    >
                        <span>{label}</span>
                    </Link>
                </Item>
            ))}
        </Wrapper>
    ) : null;
};

CategoryListing.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            tag: PropTypes.string,
            to: PropTypes.string,
        })
    ),
    searchMeta: PropTypes.object,
};

export default CategoryListing;
